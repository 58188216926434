// src/Header.js

import React, { useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Logo from '../../assets/images/logo-main-white.png';
import { Menu as MenuIcon } from '@mui/icons-material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleMenuClose3 = () => {
    setAnchorEl3(null);
  };

  return (
  <>
    <AppBar  position="static" className='header-main'>
      <Toolbar> 
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"

        >
          <Grid item xs={11} sm={7} md={6} lg={9}>
            <div className='d-flex h-68 '>
              <div>
                <img className='img-logo' src={Logo} alt="Logo" />
              </div>
              <div>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Button color="inherit" href="#home">Trainingsplan</Button>
                  <Button color="inherit" href="#about">Probetraining</Button>
                  <Button color="inherit" href="#portfolio">Elterninfos</Button>
                  <Button color="inherit" href="#contact">Services</Button>
                  {/* <Button
                    color="inherit"
                    onClick={handleMenuOpen}
                  >
                    <img className='more-icon' src={More} alt="Logo" />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose} component="a" href="#web-development">Web Development</MenuItem>
                    <MenuItem onClick={handleMenuClose} component="a" href="#mobile-development">Mobile Development</MenuItem>
                    <MenuItem onClick={handleMenuClose} component="a" href="#ui-ux">UI/UX Design</MenuItem>
                  </Menu> */}

                </Box>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                  onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>

          </Grid>
          <Grid item xs={11} sm={7} md={6} lg={3}>
            <div className='d-flex align-end'>

              <div>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                
                  <Button color="inherit" href="#home">#WEAREREADY</Button>
                  <Button color="inherit" href="#about">Shop</Button>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
       
      </Toolbar>
     
    </AppBar>
    <AppBar  position="static" className='sub-header'>
      <Toolbar> 
      <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={11} sm={7} md={6} lg={12}>
        <div className='d-flex h-48'>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button className='pl-0' color="inherit" onClick={() => navigate('/')}>Home</Button>
            <IconButton
              color="inherit"
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen2}
            >
            FC Oetwil-Geroldswil
              <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleMenuClose2}
              keepMounted
            >
              <MenuItem onClick={handleMenuClose2} component="a" href="#more1">Daten & Fakten</MenuItem>
              <MenuItem onClick={handleMenuClose2} component="a" href="#more2">Vereinsführung</MenuItem>
              <MenuItem onClick={handleMenuClose2} component="a" href="#more3">Geschichte</MenuItem>
              <MenuItem onClick={handleMenuClose2} component="a" href="#more3">Unsere Werte</MenuItem>
            </Menu>




            <IconButton
              color="inherit"
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen3}
            >
            junioren
              <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl3}
              open={Boolean(anchorEl3)}
              onClose={handleMenuClose3}
              keepMounted
            >
              <MenuItem onClick={handleMenuClose3} component="a" href="#more1">Überblick</MenuItem>
              <MenuItem onClick={handleMenuClose3} component="a" href="#more2">Ausbildungsphilosophie</MenuItem>
              <MenuItem onClick={() => { navigate('teams'); handleMenuClose3(); }} component="a" >Teams</MenuItem>
              <MenuItem onClick={handleMenuClose3} component="a" href="#more3">Kinderschutz</MenuItem>
            </Menu>

            <Button color="inherit" href="#camps">Camps</Button>
            <Button color="inherit" href="#clinics">Club Referee</Button>

            {/* Dropdown Button */}
           
          </Box>
        </div>
      </Grid>
    </Grid>
       
      </Toolbar>
     
    </AppBar>
    </>
    
  );
};

export default Header;
