const signupApi = async (url, postData) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const loginApi = async (url, postData) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const checkCredentialApi = async (url, postData) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const changePasswordApi = async (url, postData) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const postApi = async (url, token, postData) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const getApi = async (url, token) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => {
            console.error(error);
            return error;
        });
};

const fetchApi = async (url, token) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            const data = JSON.parse(result);
            return data;
        })
        .catch((error) => error);
};

const putApi = async (url, token, postData) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

const deleteApi = async (url, token) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => error);
};

export {
    signupApi,
    loginApi,
    checkCredentialApi,
    changePasswordApi,
    postApi,
    getApi,
    fetchApi,
    putApi,
    deleteApi
};