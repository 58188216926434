import { useState, useEffect, useCallback } from 'react';
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import share from '../../assets/images/share-icon.png';
import { getApi } from '../../Utils/api';
import Moment from 'moment';

const TeamPage = () => {
  Moment.locale('de-ch');

  const [expanded, setExpanded] = useState(false);

  const [teamsData, setTeamsData] = useState([]);

  const fetchTeams = useCallback(async () => {
    try {
      const getApiResponse = await getApi(`https://api.ogmaster.ch/api/fcog-web/teams/?type=junioren`, '');
      if (getApiResponse.success) {
        console.log("data=>", getApiResponse.data);
        setTeamsData(getApiResponse.data);
      } else {
        console.log('error=>', getApiResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);;
    }
  }, []);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getWeekdayName = (weekdayNumber) => {
    const number = parseInt(weekdayNumber, 10);
    if (isNaN(number) || number < 0 || number > 6) {
      return 'Ungültiger Wochentag';
    }

    let dayOfWeek ='';
    if (weekdayNumber == 1){
      dayOfWeek ='Montag'
    } else if(weekdayNumber == 2){
      dayOfWeek ='Dienstag'
    }else if(weekdayNumber == 3){
      dayOfWeek ='Mittwoch'
    }else if(weekdayNumber == 4){
      dayOfWeek ='Donnerstag'
    }else if(weekdayNumber == 5){
      dayOfWeek ='Freitag'
    }else if(weekdayNumber == 6){
      dayOfWeek ='Samstag'
    }else if(weekdayNumber == 7){
      dayOfWeek ='Sonntag'
    }else{
      dayOfWeek =''
    }

    // return Moment().isoWeekday(number).format('dddd');
    return dayOfWeek;
  };


  return (
    <>
      <Header />
      <Grid
        container
        justifyContent="center"
        alignItems="center"

      >
        <Grid item xs={11} sm={7} md={6} lg={12}>
          <div className='teams-accordion-main accordion-main'>
            <div className='title-main text-left mb-20'>
              Juniorenteams
            </div>
            {teamsData.map(team => (
              <Accordion expanded={expanded === `${team._id}`} onChange={handleChange(`${team._id}`)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${team._id}bh-content`}
                  id={`${team._id}-bh-header`}
                >
                  <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    {team.name}
                  </Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"

                  >
                    <Grid item xs={11} sm={7} md={6} lg={12}>

                    <div className='acco-sub-title mt-20 d-flex'>
                        <div className='accor-tit'>
                          Verantwortlicher Team
                        </div>
                        <div className='acco-details'>
                          {team.verantwortlicher}
                        </div>
                      </div>

                      <div className='acco-sub-title mt-10 d-flex'>
                        <div className='accor-tit'>
                          Trainingszeiten
                        </div>
                        <div className='acco-details'>


                          {(() => {
                            if (team.trainings.length > 0) {
                              return (

                                <div className="posts">

                                  {team.trainings.map(item => (
                                    <div className='accor-tit accor-date d-flex'>
                                    <div className='wc-150'>{getWeekdayName(item.day)}, </div>
                                    <div className='w-100'>  {item.fromHours}:{item.fromMinutes} - {item.toHours}:{item.toMinutes} Uhr </div>

                                    
                                    </div>
                                  ))}
                                </div>
                              );
                            }
                            return null;
                          })()}

                        </div>
                      </div>

                      

                      <div className='acco-sub-title mt-10 d-flex'>
                        <div className='accor-tit'>
                          Spielklasse
                        </div>
                        <div className='acco-details'>
                          {team.division}
                        </div>
                      </div>

                      <div className='acco-sub-title mt-10 d-flex'>
                        <div className='accor-tit'>
                          Spielmodus
                        </div>
                        <div className='acco-details'>
                          {team.gameMode}
                        </div>
                      </div>

                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}

            {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                  U8 – Keine freien Plätze im Team verfügbar
                </Typography>

              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"

                >
                  <Grid item xs={11} sm={7} md={6} lg={12}>
                    <div className='acco-title'>
                      Montag, 17:00-18:30 Uhr
                    </div>
                    <div className='acco-sub-title mt-10'>
                      Ort: Kleinfeld
                    </div>
                    <div className='acco-sub-title mt-20'>
                      Trainer: Annabell, Marlene, Jens, Christina
                    </div>

                    <div className='share-details-acc mt-20'>
                      <div>
                        <img className='img-share' src={share} alt="Logo" />
                      </div>
                      <div className='acco-sub-title '>
                        Trainer: Annabell, Marlene, Jens, Christina
                      </div>
                    </div>
                    <div className='share-details-acc mt-10'>
                      <div>
                        <img className='img-share' src={share} alt="Logo" />
                      </div>
                      <div className='acco-sub-title '>
                        Trainer: Annabell, Marlene, Jens, Christina
                      </div>
                    </div>

                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion> */}

          </div>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default TeamPage;