// src/components/About.js
import React from 'react';

const LoginPage = () => {
  return (
    <div>
      <h2>Login Page</h2>
    </div>
  );
}

export default LoginPage;