// src/Header.js

import React from 'react';
import { Grid } from '@mui/material';
import Logo from '../../assets/images/klipartz.com.png';

const Footer = () => {


    return (
        <>
            <section className='footer-main'>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={11} sm={7} md={6} lg={'auto'}>
                    <img className='footer-right-arrow' src={Logo} alt="Logo" />
                    </Grid>
                   
                </Grid>
            </section>
            <section className='footer-menu'>

            </section>
        </>

    );
};

export default Footer;
