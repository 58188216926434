import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';
import { Grid } from '@mui/material';
import blogImg from '../../assets/images/blog-card.webp';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';

const HomePage = () => {
  // const [posts, setPosts] = useState([]);

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=YOUR_ACCESS_TOKEN`
  //       );
  //       setPosts(response.data.data);
  //       console.log("response.data.data=>", response.data.data);
  //       console.log("posts=>", posts);
  //     } catch (error) {
  //       console.error('Error fetching Instagram posts:', error);
  //     }
  //   };

  //   fetchPosts();
  // }, []);

  return (
    <>
      <Header />
      <section className='blogs'>

        <Grid
          container
          // justifyContent="center"
          alignItems="center"
          spacing={3.4}
        >

          <Grid item xs={11} sm={7} md={6} lg={12}>
            <Grid
              container
              // justifyContent="center"
              alignItems="center"

            >

              <Grid item xs={11} sm={7} md={6} lg={12}>
                <div className='web-title'>
                  Social Wall
                </div>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={11} sm={7} md={6} lg={3}>

            <Card className='blog-card'>
              <div className='blog-img'>
                <img className='blog-graphics' src={blogImg} alt="Logo" />
              </div>
              <div className='blog-content-main'>
                {/* <div className='blog-date'>
                  10 Jan 2024
                </div> */}
                <div className='blog-title'>
                  <Link href='/blog-post-url' underline="none" >
                    Die U17 des FCZ ist Schweizer
                  </Link>
                </div>
                <div className='blog-pera'>
                  3:1-Sieg im Playoff-Final gegen den FC Sion
                </div>
              </div>
            </Card>
          </Grid>

        </Grid>
      </section>

      <Footer />
    </>
  );
}

export default HomePage;