// src/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from '../views/HomePage';
import LoginPage from '../views/Login';
import TeamPage from '../views/TeamPage';
const AppRouter = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<HomePage/>}/>
          <Route exact path="/teams" element={<TeamPage/>}/>
          <Route exact path="/login" element={<LoginPage/>}/>
        </Routes>
    </Router>
  );
}

export default AppRouter;
